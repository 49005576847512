@import "../../../common/style.scss";

$padding: 3px;

.app {
    color: #404040;
}

.areaFilter {
    margin-right: 5px;
}

.areaFilter > select {
}

$roomBox-size: 130px;
$icon-circumference: $roomBox-size / 8; //20px @ 150
$icon-spacing: $roomBox-size / 37.5; //4px @ 150
$icon-font-size: $roomBox-size / 10.7; //14px @ 150
$smallFontSize: $roomBox-size / 12.5; //12px @ 150
$bigFontSize: $roomBox-size / 8.3; //18px @ 150

$currentGroupWidth: 120px;
$currentGroupHeight: 70px;
$groupHeight: 50px;
$groupWidth: 70px;
$groupMargin: 8px;
$groupPadding: 4px;

$bottomButtonWidth: 150px;
$sideMenuWidth: 264px;
$detailsButtonWidth: 48px;
$fixedButtonHeight: 75px;

.roomBox {
    min-width: $roomBox-size !important;
    max-width: $roomBox-size !important;
    min-height: $roomBox-size !important;
    max-height: $roomBox-size !important;
    border: 1px solid #343a40 !important;
    padding: $padding;
    margin: 2px !important;
}

.roomBoxClickMask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10;
}

.roomBox:first-child {
    margin-left: 0px;
}

.roomBoxTitle {
    width: 100%;
    height: 15%;
    text-align: center !important;
    vertical-align: middle !important;
    padding: 0;
    margin: 0;
    font-size: $icon-font-size;
}

.roomBoxTitleIncomplete {
    background-color: orange;
    color: white;
}

.roomBoxTitleMissed {
    background-color: rgba(255, 215, 0, 1);
    color: black;
}

.roomBoxTitleDeclined {
    background-color: rgb(249, 119, 119);
    color: black;
}

.roomBoxTitleDueSoon {
    background-color: rgba(255, 255, 102, 1);
    color: black;
}

.roomBoxTitleMarked {
    background-color: rgb(218, 253, 181);
    color: black;
}


.roomBoxTitleState {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    //box-sizing: content-box;
    height: 19%;
}

.roomBoxTitleInvisible {
    background-color: transparent !important;       
}

.roomBoxNumber {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    text-align: center !important;
    vertical-align: middle !important;
}

.roomBoxNumber > h5 {
    font-size: $bigFontSize;
}

.roomBoxName {
    position: absolute;
    top: 65%;
    left: $padding;
    width: 100%;
}

.roomBoxName > p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.smallText {
    font-size: $smallFontSize;
}

.smallText > p {
    font-size: $smallFontSize;
}

.staffName {
    font-style: italic;
}

.roomBoxIcon {
    position: absolute;
    right: $padding;
    width: $icon-circumference;
    height: $icon-circumference;
    border-radius: $icon-circumference / 2;
    border: 1px solid #808000;
}

.commentBoxIcon {
    position: absolute;
    background-color: transparent;
    right: $padding;
    width: $icon-circumference;
    height: $icon-circumference;
    bottom: $padding;
    z-index: 11;
}

.cameraBoxIcon {
    position: absolute;
    background-color: transparent;
    right: $padding + $icon-circumference + $padding;
    width: $icon-circumference;
    height: $icon-circumference;
    bottom: $padding;
    z-index: 11;
}

.icon:hover {
    background-color: lightgray;
    cursor: pointer;
}

.checklistIcon {
    right: auto;
    left: 0px;
}

.roomBoxIcon > p {
    text-align: center !important;
    margin-top: -2px;
    font-size: $icon-font-size;
}

.iconF {
    bottom: $icon-spacing * 1 + ($icon-circumference) * 0;
    background-color: black;
    color: white;
}

.iconE {
    bottom: $icon-spacing * 2 + ($icon-circumference) * 1;
    background-color: yellow;
    color: black;
}

.iconS {
    bottom: $icon-spacing * 3 + ($icon-circumference) * 2;
    background-color: purple;
    color: white;
}

.iconV {
    bottom: $icon-spacing * 4 + ($icon-circumference) * 3;
    background-color: purple;
    color: white;
}

.iconP {
    bottom: $icon-spacing * 5 + ($icon-circumference) * 4;
    background-color: purple;
    color: white;
}

.CLiconF {
    top: $icon-spacing * 1 + ($icon-circumference) * 0;
    background-color: black;
    color: white;
    right: 5px;
}

.CLiconE {
    top: $icon-spacing * 2 + ($icon-circumference) * 1;
    background-color: yellow;
    color: black;
    right: 5px;
}

.CLiconP {
    top: $icon-spacing * 1 + ($icon-circumference) * 0;
    background-color: purple;
    color: white;
}

.CLiconS {
    top: $icon-spacing * 2 + ($icon-circumference) * 1;
    background-color: purple;
    color: white;
}

.CLiconV {
    top: $icon-spacing * 3 + ($icon-circumference) * 2;
    background-color: purple;
    color: white;
}

.fixedBottomLeft, .fixedBottomRight {
    position: fixed;
    bottom: 0px;
    width: $bottomButtonWidth;
    height: $fixedButtonHeight;
    text-align: center !important;
    vertical-align: middle !important;
    padding: 30px 0px 30px 0px;
}

.fixedBottomLeft {
    left: $sideMenuWidth + 15px;
}

.fixedDetailsButton {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 73px - $detailsButtonWidth;
    width: $detailsButtonWidth;
    height: $detailsButtonWidth;
    left: $sideMenuWidth + 15px + $bottomButtonWidth + 10px;
    text-align: center !important;
    vertical-align: middle !important;
}


.fixedBottomRight {
    right: 0px;
}

.disabledButton {
    background-color: lightgray !important;
    color: gray !important;
}

.fixedComment {
    position: fixed;
    bottom: 2px;
    width: unset !important;
    cursor: pointer;
    left: $sideMenuWidth + 15px + $bottomButtonWidth + 10px + $detailsButtonWidth + 10px;
    right: $bottomButtonWidth + 10px;
}

.fixedBottom {
    position: fixed;
    bottom: 0px;
    padding-bottom: 10px;
    width: unset !important;
    height: 175px + 10px;
    background-color: rgba(244, 247, 250, 0.90);
    left: $sideMenuWidth;
    right: 0px;
    z-index: 1070;
}

.overlay {
    cursor: pointer;
    background-color: rgba(244, 247, 250, 0.80);
    display: flex;
    position: fixed;
    z-index: 1060;
    top: 0;
    right: 0;
    left: $sideMenuWidth;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.625em;
    overflow-x: hidden;
    transition: background-color 0.1s;
    -webkit-overflow-scrolling: touch;
}

.fixedCommentArea {    
    resize: none;
    border-radius: 0px !important;
    padding: 1.5em 3px 3px 3px !important;
    color: black !important;
}

.fixedCommentPlaceholder {
    position: absolute;
    top: 2px;
    left: 4px;
}


.fixedSupervisorNoteLink {
    position: fixed;
    bottom: 10px;
    right: $bottomButtonWidth + 15px;
}

.fixedCommentPhotoButton {
    position: fixed;
    bottom: 10px;
    right: $bottomButtonWidth + 15px;
}

.lightPink {
    background-color: $lightPink;
}

.lightBlue {
    background-color: $lightBlue;
}

.incomplete {
    background-color: $incomplete;
}

.complete {
    background-color: $complete;
}

.notrequired {
    background-color: $notrequired;
}

.pending {
    background-color: $pending;
}

@media only screen and (min-width:650px) {

    .shortcutBoxIcon {
        padding-top: 0.5rem;
    }

    .shortcutBoxIcon > i {
        font-size: 70px;
        width: 70px;
        height: 70px;
        marginTop: 10px;
        lineHeight: 1.5;
    }

    .shortcutBoxIcon > svg {
        width: 70px;
        height: 70px;
        marginTop: 10px;
        lineHeight: 1.5;
    }

    .shortcutBox {
        min-width: 140px;
        min-height: 140px;
        width: 140px;
        max-width: 140px;
        max-height: 140px;
        height: 140px;
        white-space: normal;
        perspective: 1000px;
        border: 1px solid black;
    }
}

@media only screen and (min-width:650px) and (max-width: 1024px) {

    .fixedBottomLeft {
        left: 0px;
    }

    .fixedComment {
        left: $bottomButtonWidth + 10px;
        right: $bottomButtonWidth + 10px;
    }

    .fixedDetailsButton {
        left: $bottomButtonWidth + 10px;
    }

    .fixedBottom, .overlay {
        left: 0px;
    }
}

@media (max-width:650px) {

    .shortcutBoxIcon {
        padding-top: 0.25rem;
    }

    .shortcutBoxIcon > i {
        font-size: 40px;
        width: 40px;
        height: 40px;
        marginTop: 10px;
        lineHeight: 1.5;
    }

    .shortcutBoxIcon > svg {
        width: 40px;
        height: 40px;
        marginTop: 10px;
        lineHeight: 1.5;
    }

    .shortcutBox {
        min-width: 100px;
        min-height: 100px;
        width: 100px;
        max-width: 100px;
        max-height: 100px;
        height: 100px;
        white-space: normal;
        perspective: 1000px;
        border: 1px solid black;
        font-size: 12px;
    }

    .dashboardHeader {
        position: static !important;
        transform: translate(0%, 0%) !important;
    }

    .fixedBottomLeft {
        left: 0px !important;
    }

    .fixedBottomLeft, .fixedBottomRight {
        width: $bottomButtonWidth - 50px !important;
    }

    .fixedComment {
        left: ($bottomButtonWidth - 50px) + 10px !important;
        right: ($bottomButtonWidth - 50px) + 10px !important;
    }

    .operationBoxText {
        padding-right: 1px !important;
        padding-left: 1px !important;
    }

    #sc-cancel {
        position: absolute;
        bottom: 0px;
        left: 0px;
    }

    #sc-check-clean {
        position: absolute;
        bottom: 0px;
        right: 0px;
    }

    .fixedBottom, .overlay {
        left: 0px !important;
    }

    .fixedBottom {
        height: 220px + 10px !important;
    }

    .fixedDetailsButton {
        left: $bottomButtonWidth + 10px !important;
    }

    .scrollArea {
        div {
            flex-wrap: wrap !important;
        }
    }

    .groupArea {
        justify-content: left !important;
        flex-wrap: wrap !important;
    }

    .groupTitle, .groupTitleCurrent {
        margin: 2px !important;
        margin-top: 2px !important;
    }

    .groupTitleCurrent {
        width: $currentGroupWidth !important;
        min-width: $currentGroupWidth !important;
        max-width: $currentGroupWidth !important;
        height: $groupHeight + 20px !important;
        min-height: $groupHeight + 20px !important;
        max-height: $groupHeight + 20px !important;
    }

    .phone-hide {
        display: none !important;
    }

    .room-report-title {
        display: flex !important;
        flex-direction: row !important;
        justify-content: space-between !important;
        width: 95% !important;
    }

    .fixedCommentArea {
        text-align: center !important;
    }

    .roomHistoryTable {
        font-size: 14px;
    }
}

.force-mobile-view {
    .fixedBottomLeft {
        left: 0px;
    }

    .fixedComment {
        left: $bottomButtonWidth + 10px + $detailsButtonWidth + 10px;
    }

    .fixedDetailsButton {
        left: $bottomButtonWidth + 10px;
    }

    .fixedBottom, .overlay {
        left: 0px;
    }
}

.fixedBackground {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 75px;
    background-color: #f4f7fa;
    opacity: 0.85;
}

.scrollAreaPlaceholder {
    height: 75px;
    width: 100%;
}

.operationBox {
    min-width: 170px;
    min-height: 170px;
    width: 170px;
    max-width: 170px;
    max-height: 170px;
    height: 170px;
    white-space: normal;
    perspective: 1000px;
    border: 1px solid black;

    &Selected {
        border-width: 3px !important;
    }
}

.operationBoxInner {
    position: relative;
    height: 100%;
    width: 100%;
    transition: transform 1s;
    transform-style: preserve-3d;
}

.operationBoxNow, .operationBoxFlip {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.operationDetailsButton {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 5px;
    right: 5px;
}

.operationBoxFlip {
    transform: rotateY(180deg);
}

.operationBoxIcon {
    position: absolute;
    width: 100%;
}

.operationBoxIcon > i {
    font-size: 90px;
}

.operationBoxText {
    position: absolute;
    bottom: 0px;
    width: 100%;
    padding: 0.5rem;
}

.titleBar {
    margin: -15px 0px 0px -15px;
    padding: 15px;
    border-bottom: 1px solid black;
}

.titleBar > div > i {
    font-size: 60px;
}

.groupTitle, .groupTitleCurrent {
    margin: $groupMargin;
    padding: $groupPadding;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #808000;
}

.groupTitle {
    font-size: 10px;
    width: $groupWidth;
    min-width: $groupWidth;
    max-width: $groupWidth;
    height: $groupHeight;
    min-height: $groupHeight;
    max-height: $groupHeight;
    margin-top: (($currentGroupHeight - $groupHeight) / 2) + $groupMargin !important;
}

.groupTitleCurrent {
    font-size: 12px;
    width: $currentGroupWidth;
    min-width: $currentGroupWidth;
    max-width: $currentGroupWidth;
    height: $currentGroupWidth;
    min-height: $currentGroupHeight;
    max-height: $currentGroupHeight;
}

.scrollArea {
    overflow: auto;
    white-space: nowrap;
}

.groupArea {
    display: flex;
    justify-content: center;
}

.room-report-title {
    display: flex;
    flex-direction: column;
}

//Show scrollbar on mobile
::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 12px;
}

::-webkit-scrollbar:horizontal {
    height: 12px;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}
//End show scrollbar on mobile

.residentPhoto {
    height: 94px;
    padding-right: $icon-circumference + $padding + 5px;
    padding-left: $icon-circumference + $padding;
    position: relative;

    img {
        height: 100%
    }
}

.noRoomsContainer {            
    height: 400px;
    font-size: 30px;
}

.dashboardHeader {            
    font-weight: 400;
    font-size: 17px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

$photoHeight: 12em;
$photoWidth: $photoHeight;
$photoMargin: 2px;

.photoBox {
    min-width: $photoWidth !important;
    max-width: $photoWidth !important;
    min-height: $photoHeight !important;
    max-height: $photoHeight !important;
    border: none !important;
    padding: $padding;
    margin: $photoMargin;

    img {
        width: 100%;
        height: 100%;
    }
}

.photoGallery {
    overflow-y: auto;
    max-height: ($photoHeight)*2.5;
}

.deletePhotoButton {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;    
    color: red;

    &:hover {
        transform: scale(1.2, 1.2);
    }
}

.roomHistoryForm {
}

.roomHistoryTable {
    overflow-y: auto;
}

.dashboard_AuditReport {
    border: 2px solid cornflowerblue;
    background-color: white;
    border-radius: 10px;

    .dasboard_ReportTitle {
        font-weight: bold;
        border-radius: 5px;
        background-color: cornflowerblue;
        color: white;
        margin-left: -0.5rem;
        margin-top: -0.5rem;
        padding: 0.5rem
    }

    .dasboard_Header {
        font-weight: bold;
    }

    .dasboard_NotComplete {
        color: lightgray;
    }
}



.dashaboard_AuditBox {
    position: relative;

    .AuditBox_overlay {
        position: absolute;
        width: 100%;
        height: 100%;        
        z-index: 2;

        &-pass {
            background-color: rgba(0, 255, 0, 0.2);
            border: 3px solid $complete;
            z-index: -1;
        }
        
        &-unobserved {
            background-color: rgba(0, 0, 0, 0.2);
            border: 3px solid gray;
        }

        &-fail {
            background-color: rgba(255, 0, 0, 0.2);
            border: 3px solid red;
        }

        &-disabled {
            background-color: rgba(255, 255, 255, 0.8);
            border: 3px solid rgb(247, 242, 242);
        }
    }
}

.sigPad {
    width: 100%;
    min-height: 50vh;
    height: 100%;
}

.sigLine {
    width: 100%;
    height: 0px;
    border-bottom: 2px solid black;
}

.lightPinkButton {
    background-color: $lightPink !important;
    color: black !important;
    border-width: 0px !important;
}

.redButton {
    background-color: red !important;
    color: white !important;
    border-width: 0px !important;
}

.lightBlueButton {
    background-color: $lightBlue !important;
    color: black !important;
    border-width: 0px !important;
}

.declineFormBorder {
    border: .5rem solid black !important;
}

.declineFormButton {
    width: 10em !important;
}


