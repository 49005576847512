$stateButtonHeight: 42px;
$stateButtonWidth: 150px;

.state-button {
    border-radius: 8px;        
    cursor: pointer;
    text-align: center;
    vertical-align: middle;
    height: $stateButtonHeight;
    //width: $stateButtonWidth;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    &:hover {      
        box-shadow: 0 0 5px 5px rgba(236, 61, 17, 0.40);
      }
}

.state-button-resident {
    border: 4px black;
}

.state-button-container {
    margin: 10px 10px;
    padding: 2px;   

    &-selected {
        border-radius: 5px;
        outline: 0;
        box-shadow: 0 0 5px 5px rgba(0, 123, 255, 25%);
    }
}

.state-clean-selected {
    outline: 0;
    box-shadow: 0 0 5px 5px rgba(0, 123, 255, 25%);
}

.state-align-right {
    margin-right: auto !important;
    float: left;
}
