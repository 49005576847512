.image-preview {
  position: relative;
  text-align: center;
}

.image-max {
  max-width: 20em;
  max-height: 100%;
}

.image-preview > img {
  width: 20em;
}

@media(max-width:768px){
  .image-preview > img {
    width: 12em;
  }
}

/* fullscreen enable by props */
.image-preview-fullscreen > img {
  width: 100vw;
  height:100vh;
}
