$white: #ffffff;
$black: #000000;
$lightPink: #ffa2fe;
$lightBlue: #8fd1f8;
$incomplete: #e4cd4d;
$complete: #C5E379;
$notrequired: #b9b9b9;
$pending: #FFFF99;

.iconButton {
    cursor: pointer;

    &:hover {
        transform: scale(1.2, 1.2);
    }
}

@media screen {
    .print-only {
        display: none !important;
      }
}

@media print {
    .no-print {
      display: none !important;
    }    
    
    body {
        /* No background for body */
        background-color: white !important;
    }

    @page { size: legal landscape; }

    /* Copy some styles from bootstrap to fix printing CardGroup */
    .card-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
    }
    .card-group > .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0;
    }
    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }
    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-top,
    .card-group > .card:not(:last-child) .card-header {
      border-top-right-radius: 0;
    }
    .card-group > .card:not(:last-child) .card-img-bottom,
    .card-group > .card:not(:last-child) .card-footer {
        border-bottom-right-radius: 0;
    }
    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-top,
    .card-group > .card:not(:first-child) .card-header {
        border-top-left-radius: 0;
    }
    .card-group > .card:not(:first-child) .card-img-bottom,
    .card-group > .card:not(:first-child) .card-footer {
        border-bottom-left-radius: 0;
    }
}

.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media only screen and (min-width: 800px) and (max-width: 1500px) {

    .swal2-container {
        padding: 2px !important;
    }

    .swal2-popup {
        width: 80% !important;
        padding: 2px !important;
    }

    .swal2-content {
        padding: 0px !important;
    }

    .swal2-title {
        font-size: 1.25em !important;
    }
}

@media only screen and (max-width: 800px) {

    .swal2-container {
        padding: 2px !important;
    }

    .swal2-popup {
        width: 100% !important;
        padding: 2px !important;
    }

    .swal2-content {
        padding: 0px !important;
    }

    .swal2-title {
        font-size: 1.25em !important;
    }
}

.dropDownControl {
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition-property: border-color, box-shadow; 
    transition-duration: 0.15s, 0.15s;
    transition-timing-function: ease-in-out, ease-in-out;
    transition-delay: 0s, 0s;
    outline: 0;
    
    :focus {
        border-radius: 0.25rem;
        box-shadow: 0 0 0 0.25rem rgba(38, 143, 255, 0.25);
    }
    
    a {
        color: #495057;
    }
}



